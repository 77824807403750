// src/services/authService.js
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const API_URL = `${process.env.VUE_APP_API_HOST}/api`; // Remplacez par l'URL de votre API

export async function login(email, mot_de_passe) {
    try {
        const response = await axios.post(`${API_URL}/users/login`, { email, mot_de_passe });
        if (response.data.token) {
            localStorage.setItem('token', response.data.token); // Stocker le token dans le stockage local
        }
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.error || 'Erreur lors de la connexion');
    }
}

export async function forgotPassword(email) {
    try {
        const response = await axios.post(`${API_URL}/users/forgot-password`, { email });
        return response.data; // Retourne la réponse en cas de succès
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Erreur lors de la demande de réinitialisation.');
    }
}

export async function updateUser(userId, userData) {
    try {
        const token = getToken(); // Récupère le token pour l'authentification
        const response = await axios.put(`${API_URL}/users/${userId}`, userData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data; // Retourne les données mises à jour
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Erreur lors de la mise à jour des informations utilisateur.');
    }
}

export async function getUserInfo(userId) {
    try {
        const token = getToken(); // Récupère le token pour l'authentification
        const response = await axios.get(`${API_URL}/users/info/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data; // Retourne les données utilisateur
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Erreur lors de la récupération des informations utilisateur.');
    }
}

export function logout() {
    localStorage.removeItem('token'); // Supprime le token pour déconnecter l'utilisateur
}

export function getToken() {
    return localStorage.getItem('token');
}

export function isAuthenticated() {
    return !!getToken();
}

export function getUserRole() {
    const token = getToken();
    if (token) {
        const decoded = jwtDecode(token);
        return decoded.role;
    }
    return null;
}

export function getUserIdFromToken() {
    const token = localStorage.getItem('token');
    if (token) {
        const decoded = jwtDecode(token);
        return decoded.id; // Assurez-vous que `id` correspond bien au champ dans votre JWT
    }
    throw new Error("Token invalide ou absent.");
}


export async function register(userData) {
    try {
        const response = await axios.post(`${API_URL}/users/register`, userData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Erreur lors de la création de compte');
    }
}

axios.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export async function getProviders() {
    try {
        const response = await axios.get(`${API_URL}/users/providersInfo`);
        return response.data; // Retourne les prestataires
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Erreur lors de la récupération des prestataires');
    }
}

export async function getProvidersForPublic() {
    try {
        const response = await axios.get(`${API_URL}/users/providers`);
        return response.data; // Retourne les prestataires
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Erreur lors de la récupération des prestataires');
    }
}

// Récupérer les photos d'un utilisateur
export async function getPortfolioPhotos(userId) {
    try {
        const response = await axios.get(`${API_URL}/photos/user/${userId}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
        return response.data.photos;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Erreur lors de la récupération des photos.');
    }
}

// Ajouter une photo au portefeuille
export async function uploadPortfolioPhoto(photoFile) {
    try {
        const formData = new FormData();
        formData.append('photo', photoFile);

        const response = await axios.post(`${API_URL}/photos/upload`, formData, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Erreur lors du téléversement de la photo.');
    }
}

// Supprimer une photo
export async function deletePortfolioPhoto(photoId) {
    try {
        const response = await axios.delete(`${API_URL}/photos/${photoId}`, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Erreur lors de la suppression de la photo.');
    }
}

// Téléverser un document de certification
export async function uploadDocument(documentFile) {
    try {
        const formData = new FormData();
        formData.append('document', documentFile);

        const response = await axios.post(`${API_URL}/upload/document`, formData, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Erreur lors du téléversement du document.');
    }
}

/**
 * Téléverse une photo de profil pour l'utilisateur
 * @param {FormData} formData - Données du formulaire contenant l'image
 * @returns {Promise<Object>} - Réponse de l'API contenant les détails de l'image téléversée
 */
export async function uploadProfilePhoto(formData) {
    try {
        const response = await axios.post(`${API_URL}/upload/profile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`, // Ajoutez un jeton JWT si nécessaire
            },
        });
        return response.data; // Retourne la réponse contenant les détails de l'image
    } catch (error) {
        console.error('Erreur lors du téléversement de la photo de profil:', error);
        throw new Error(error.response?.data?.message || 'Erreur inconnue');
    }
}
